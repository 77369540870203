'use client';

import { CategoryLink } from '@/sanity/fetchCategories';
import { EventCard } from '@/sanity/fetchEvents';
import { TeaserLastSlide } from '@/shared/types/teasers';
import { cn } from '@/utils/cn';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import '@/styles/vendor/swiper.css';
import { Navigation, Pagination, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import HIHButton from '../base/Button';
import HIHImage from '../base/Image';
import { RoundedIconButton } from '../base/RoundedIconButton';
import { CategoryTag } from '../base/Tag';
import EventFacts from './EventFacts';
import { CONTENT_TYPE_PAGE, URL_PAGE_SOON, URL_PATH_EVENT } from '@/shared/constants';
import { Announcement } from '@/shared/types/announcement';
import { format } from 'date-fns';
import Image from 'next/image';
import { ImageMeta } from '@/shared/types/imageMeta';
import { contentTypeToPath } from '@/utils/api-helpers';
import Underline from '../base/Underline';
import HIHLink from '../base/Link';
import { de } from 'date-fns/locale';
import { useState } from 'react'; // Import the German locale

export default function EventsTodaySlider({
  events,
  lastSlide,
  className,
  announcement,
}: {
  events?: EventCard[];
  lastSlide?: TeaserLastSlide;
  className?: string;
  announcement?: Announcement;
}) {
  const today = new Date();
  const formattedDate = format(today, 'dd. MMMM', { locale: de });

  const [MouseWheelEnabled, setMouseWheelEnabled] = useState(true);

  return (
    <div className={cn(className)}>
      <div className="grid lg:grid-cols-12 items-end pb-5xl">
        {/* Datum flexibel setzen */}

        <div className="font-display text-headline-lg lg:text-display-lg uppercase lg:col-span-7 pb-2xl lg:pb-0 text-center lg:text-left">
          Alle Empfehlungen für <br />
          <Underline>heute</Underline>, den {formattedDate}
        </div>
        <div className="lg:col-span-5 text-center lg:text-left lg:pl-3xl">
          Heute noch nichts vor? Lass dich von unserer Auswahl inspirieren. Ob Konzert,
          Flohmarkt oder Ausstellung, hier findest du dein nächstes Alltagsabenteuer.
        </div>
      </div>
      <Swiper
        slidesPerView={1}
        modules={[Pagination, Navigation, Mousewheel]}
        loop={true}
        pagination={{
          clickable: true,
          el: '.swiper-today-slider-pagination',
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        onLock={() => setMouseWheelEnabled(false)}
        onUnlock={() => setMouseWheelEnabled(true)}
        className={cn(
          'overflow-visible',
          !MouseWheelEnabled ? 'swiper-no-mousewheel' : undefined,
        )}
      >
        {announcement && announcement.active && (
          <SwiperSlide
            key="announcementSlide"
            className={cn('overflow-visible  p-[2px] w-full h-full')}
          >
            <ContentSlide
              dynamicImage={announcement.image}
              title={announcement.title}
              copy={announcement.message}
              buttonTitle={announcement.link?.linkLabel}
              announcement={announcement}
              priority
            />
          </SwiperSlide>
        )}
        {events?.map((event: EventCard) => {
          return (
            <SwiperSlide key={event._id} className={cn(' p-[2px] group')}>
              <div className="grid lg:grid-cols-12 lg:gap-3xl items-end">
                <div className=" lg:col-span-7">
                  <HIHLink href={`/${URL_PATH_EVENT}/${event.slug.current}`}>
                    <div className=" overflow-hidden aspect-4/3 rounded-2xl">
                      <HIHImage
                        image={event.coverImage}
                        width={1200}
                        height={800}
                        priority
                        lazy={false}
                        className=" object-cover rounded-2xl w-full h-full"
                        hasPlaceholder
                      />
                    </div>
                  </HIHLink>
                </div>
                <div className="relative lg:static lg:col-span-5 bg-sys/surface/surface-container-lowest pt-3xl pb-4xl px-3xl rounded-2xl flex flex-col shadow-1 top-[-20px] h-[300px] lg:h-full justify-start lg:justify-center">
                  <div>
                    <div className="flex gap-sm">
                      {event.categories
                        ?.slice(0, 2)
                        .map((category: CategoryLink) => (
                          <CategoryTag key={category._id} category={category} />
                        ))}
                    </div>
                    <HIHLink href={`/${URL_PATH_EVENT}/${event.slug.current}`}>
                      <div className=" font-display text-headline-sm lg:text-headline-lg xl:text-display-sm pt-3xl line-clamp-3 lg:min-h-auto">
                        {event.title}
                      </div>
                    </HIHLink>

                    <EventFacts event={event} className="pt-3xl" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        {lastSlide && (
          <SwiperSlide
            key="lastSlide"
            className={cn('overflow-visible p-[2px] w-full h-full')}
          >
            <ContentSlide
              staticImageUrl="/images/today-slider-empty-state.png"
              title={lastSlide.title}
              copy={lastSlide.copy}
              linkUrl={URL_PAGE_SOON}
              buttonTitle={lastSlide.linkLabel}
            />
          </SwiperSlide>
        )}
        <SwiperButtons />
      </Swiper>
    </div>
  );
}

const SwiperButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="flex items-center justify-center mt-lg lg:mt-5xl gap-xl">
      <RoundedIconButton
        onClick={() => swiper.slidePrev()}
        src="/icons/icon-arrow-left.svg"
        alt="Vorheriges Item"
      />
      <div className="flex items-center  p-lg rounded-3xl bg-sys/surface/surface-container-lowest">
        <div className="swiper-today-slider-pagination flex justify-center" />
      </div>
      <RoundedIconButton
        onClick={() => swiper.slideNext()}
        src="/icons/icon-arrow-right.svg"
        alt="Next"
      />
    </div>
  );
};

function renderAnnouncementButton(announcement: Announcement) {
  const { link } = announcement;
  if (!link || !link.linkLabel) return null;

  // external link has priority over internal link
  if (link.linkExternal && link.linkExternal.href) {
    return (
      <HIHButton
        link={link.linkExternal.href}
        title={link.linkLabel}
        blank={link.linkExternal.blank}
        className="inline-block"
        size="md"
        showArrowAfter
      />
    );
  }

  if (link.linkInternal && link.linkInternal.slug && link.linkInternal.type) {
    const pathPrefix =
      link.linkInternal.type === CONTENT_TYPE_PAGE
        ? ''
        : `/${contentTypeToPath(link.linkInternal.type)}`;
    const linkPath = `${pathPrefix}/${link.linkInternal.slug}`;

    return <HIHButton link={linkPath} title={link.linkLabel} className="inline-block" />;
  }

  return null;
}

const ContentSlide = ({
  title,
  copy,
  linkUrl,
  buttonTitle,
  staticImageUrl,
  dynamicImage,
  announcement,
  priority,
}: {
  title: string;
  copy?: string;
  linkUrl?: string;
  buttonTitle?: string;
  staticImageUrl?: string;
  dynamicImage?: ImageMeta;
  announcement?: Announcement;
  priority?: boolean;
}) => {
  return (
    <div className="lg:grid lg:grid-cols-5 lg:gap-xl items-end">
      <div className=" lg:col-span-3">
        {dynamicImage ? (
          <HIHImage
            image={dynamicImage}
            width={800}
            height={600}
            className=" object-cover aspect-4/3 rounded-2xl "
            priority={priority}
          />
        ) : (
          staticImageUrl && (
            <Image
              src={staticImageUrl}
              alt="Last Slide Image"
              width={1600}
              height={1200}
              className=" object-cover aspect-4/3 rounded-2xl "
              priority={priority}
            />
          )
        )}
      </div>
      <div className="relative lg:static lg:col-span-2 bg-sys/surface/surface-container-lowest py-5xl px-3xl rounded-2xl flex flex-col flex-1 justify-center shadow-1 top-[-20px] text-center h-[300px] lg:h-full">
        <div>
          <h2 className=" font-display text-headline-sm lg:text-headline-lg xl:text-display-sm  line-clamp-3">
            {title}
          </h2>
          {copy && <p className="mb-3xl text-body-md lg:text-body-lg pt-3xl">{copy}</p>}

          {announcement ? (
            renderAnnouncementButton(announcement)
          ) : (
            <HIHButton
              link={linkUrl ?? '/'}
              title={buttonTitle ?? 'Mehr erfahren'}
              className=" inline-block"
              size="md"
              showArrowAfter
            />
          )}
        </div>
      </div>
    </div>
  );
};
